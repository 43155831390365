import React, { useState, useEffect } from "react";
import { Box, Text, MaxWidth, Columns, Column } from "@sqymagma/elements"
import { covidPhases, indicators } from '../data/phasesData'
import { theme } from "../utils/themeGet"

import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/Seo";

const colorCombos = {
    "brand02" : "text06",
    "brand04" : "text01",
    "brand05" : "text06",
    "brand03" : "text01",
}

export default () => {

    const imageData = useStaticQuery(graphql`
        fragment phaseBgImage on File {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64

                }
            }
        }
        query {
            cover: file(relativePath: {eq: "phases-bg/cover.png"}) {
                ...phaseBgImage
            }
            intro: file(relativePath: {eq: "phases-bg/intro.png"}) {
                ...phaseBgImage
            }
            newNormal: file(relativePath: {eq: "phases-bg/new-normal.png"}) {
                ...phaseBgImage
            }
            phases: allFile(filter: {relativeDirectory: {eq: "phases-bg"}, name: {regex: "/phase/"}}, sort: {fields: relativePath, order: ASC}) {
                edges {
                  node {
                    ...phaseBgImage
                  }
                }
            }
        }
    `)

    const [ phases, setPhases ] = useState(null)
    const { edges } = imageData.phases;
    useEffect(() => {
        setPhases(covidPhases.map((phase, idx) => {
            return {
                ...phase,
                imageData: edges[idx]
            }
        }
        ))
    }, [edges])


   return (
    <Box>

      <SEO
        title={'Guía de la desescalada'}
        description={`Fases y fechas de la desescalada. Coronavirus en España`}
      />

        <PhasesSection img={imageData && imageData.cover.childImageSharp.fluid} bg="ui07">
            <MaxWidth>
                <Box pt={['20vh']} pb={['xxl']} maxWidth="1000px">
                    <Text as="h1" textStyle="tera" color="brand04" mb="l">
                        Guía de la desescalada
                    </Text>
                    <Text as="h2" textStyle="mega" color="text06">Se estima que la transición durará unas ocho semanas</Text>
                </Box>
            </MaxWidth>
        </PhasesSection>
        
        <PhasesSection img={imageData && imageData.intro.childImageSharp.fluid} bg="ui06">
            <MaxWidth>
                <Box py={["l","xl","xxl"]}>
                    <Box mb="80px">
                        <Text as="h2" textStyle="mega" color="brand04" mb="m">Calendario</Text>
                        <Text as="h2" textStyle="xxl-light" color="text06">
                            Las fases de desescalada vendrán determinadas por la situación de cada uno de los territorios en cuatro ámbitos principales: salud pública, movilidad, dimensión social y situación económica.
                        </Text>
                    </Box>
                    <Box>
                        <Text as="h2" textStyle="mega" color="brand04" mb="l">Indicadores para cambiar de fase</Text>
                        <Columns hs="24px" alignItems="stretch">
                        {indicators.map((el,idx) => (
                            <BorderedGrid phaseIdx={idx} color="brand01">
                                <PhaseIndicator {...el}/>
                            </BorderedGrid>
                        ))}
                        </Columns>
                    </Box>
                </Box>
            </MaxWidth>
        </PhasesSection>

        <Box as="section" position="relative">

            {phases && phases.map((phase, idx) => 
                (
                <PhasesSection img={phase.imageData.node.childImageSharp.fluid} bg={Object.keys(colorCombos)[idx]}>
                    <MaxWidth>
                        <Box py={["xl"]}>
                            <DottedLine top={idx === 0 ? ["7.5rem", null, "10rem"] : 0} bottom={0} height={"100%"}/>
                            <PhaseTitle {...phase} color={Object.values(colorCombos)[idx]}/>
                            <Columns hs="32px" alignItems="stretch">
                            {phase.activities.map((el,phaseIdx) => (
                                <BorderedGrid phaseIdx={phaseIdx} idx={idx}>
                                    <PhaseItem {...el} idx={phaseIdx} textColor={Object.values(colorCombos)[idx]}/>
                                </BorderedGrid>
                            ))}
                            </Columns>
                        </Box>
                    </MaxWidth>
                </PhasesSection>
            ))}
        </Box>
        <PhasesSection img={imageData && imageData.newNormal.childImageSharp.fluid} bg="brand06">
            <MaxWidth>
                <DottedLine top={0} bottom={"3rem"} height={"9rem"}/>
                <Box py={['xl']} position="relative">
                    <Text as="h2" textStyle="tera" color="text06" mb="l">
                        Situación de nueva normalidad
                    </Text>
                    <Text as="h2" textStyle="xxl" color="text06">
                        Se mantendrán las medidas de higiene y el distanciamiento.
                    </Text>
                </Box>
            </MaxWidth>
        </PhasesSection>
        

        <Box bg="ui08" py={["l", null, "xl"]}>
            <MaxWidth>
                <Text color="text06">
                *El proceso de desescalada será gradual, asimétrico, coordinado con las comunidades autónomas, y adaptable a los cambios de orientación necesarios en función de la evolución de los datos epidemiológicos y del impacto de las medidas adoptadas.
                </Text>
            </MaxWidth>
        </Box>
    </Box>
  )
}


const PhaseIndicator = ({ text, img }) => (
    <Box p="m">
        <img 
            alt={text}
            css={`{ filter: grayscale(100%); }`}
            src={`/images/svg/phases-guide/indicator-${img}.svg`}
        />
        <Text as="p" textStyle="l-semi" color="text06" mr="s">{text}</Text>
    </Box>
)

const PhaseItem = ({ text, img, textColor }) => (
    <Box p="m">
        <img 
            alt={text}
        css={`{  
            filter: grayscale(100%); 
            mix-blend-mode: luminosity;
        }`}
        src={`/images/svg/phases-guide/${img}.svg`}/>
        <Text as="p" textStyle="l-semi" color={textColor} mr="s">{text}</Text>
    </Box>
)

const PhasesSection = ({img, children, bg}) => (
    <BackgroundImage
            width="100vw"
            overflow="hidden"
            Tag="section"
            fluid={img}
            style={{
                backgroundBlendMode: "multiply",
                backgroundSize: "cover",
                backgroundPosition: "top center",
            }}
            css={`{
                background-color: ${theme(`color.${bg}`)};
            }`}
        >
            <Box pl={[0, "l"]}>
                {children}
            </Box>
    </BackgroundImage>
)

const PhaseTitle = ({period, phaseNum, name, color}) => (
    <Box mb="xl" position="relative">
        {period && 
        <Text as="h4" textStyle="xxl-banner" color={color} mb="xs">
            {period}
        </Text>
        }
        <Text as="h2" textStyle="tera" color={color} mb="xs">{phaseNum}</Text>
        <Text as="h3" textStyle="mega" color={color}>{name}</Text>
    </Box>
)

const BorderedGrid = ({ children, phaseidx, idx = null, color = null}) => {

    const borderColor = idx ? Object.values(colorCombos)[idx] : color;
    return (
    <Column width={[1, 1/2, 1/3, null, 1/4]} key={phaseidx}
        position="relative"
        css={`{
            &:after {
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                opacity: .4;
                border-top: 0;
                border-bottom: 0;
            }
        
            @media(min-width: 560px) {
                &:after {
                    border-right: 2px dotted ${theme(`color.${borderColor}`)};
                    border-top: 2px dotted ${theme(`color.${borderColor}`)};
                }
            }
        
            @media (max-width: 767px) and (min-width: 560px) {
                &:after {
                    border-right: 2px dotted ${theme(`color.${borderColor}`)};
                    border-top: 2px dotted ${theme(`color.${borderColor}`)};
                }

                &:nth-child(2n){
                    &:after {
                        border-right:none
                    }
                }
                &:nth-child(-n+2) {
                    &:after {
                        border-top:none
                    }
                }
            }

            @media (max-width: 1139px) and (min-width: 768px) {
                &:after {
                    border-right: 2px dotted ${theme(`color.${borderColor}`)};
                    border-top: 2px dotted ${theme(`color.${borderColor}`)};
                }
                &:nth-child(3n){
                    &:after {
                        border-right:none
                    }
                }
                &:nth-child(-n+3) {
                    &:after {
                        border-top:none
                    }
                }
            }

            @media (min-width: 1139px){
                &:after {
                    border-right: 2px dotted ${theme(`color.${borderColor}`)};
                    border-top: 2px dotted ${theme(`color.${borderColor}`)};
                }
                &:nth-child(4n){
                    &:after {
                        border-right:none
                    }
                }
                &:nth-child(-n+4) {
                    &:after {
                        border-top:none
                    }
                }
            }

        }`}
    >
        {children}
    </Column>
)}



export const DottedLine = ({top, bottom, height}) => (
    <Box
    position="absolute"
    top={top}
    bottom={bottom}
    height={height}
    left={["1.5rem", null, "2.3rem"]}
    display={["none", "block"]}
    css={`
        border-left: 2px dotted ${theme("color.ui07")};
        z-index: 1;
    `}
    >
        <Point top={top !== 0 ? 0 : ["7.5rem", null, "10rem"]}/>
    </Box>
)

export const Point = ({top}) => {
    return (
    <Box 
        height="100%" 
        left="-.6rem"
        top={top} bottom="0"
        position="absolute" 
        css={`{
            z-index: 10;
            transform:translateY(-12px);
        }`}
    >
        <Box 
        width="16px" height="16px" 
        bg="ui01"
        borderRadius="50%"
        zIndex="1"
        css={`{
            transform:translateY(50%);
            content:" ";
            box-shadow: 0 0 0 12px ${theme("color.brand01")}
        }`}
        ></Box>
    </Box>
)}


export const covidPhases = [
    {
        phaseNum: "Fase 0",
        name: "Preparación",
        period: "Abril - Mayo",
        activities: [
            {
                img: "paseos",
                text: "Paseos acompañados"
            },
            {
                img: "deporte",
                text: "Deporte de forma individual"
            },
            {
                img: "entrenamientos",
                text: "Entrenamientos individuales de deportistas profesionales y federados y entrenamiento básico de ligas profesionales."
            },
            {
                img: "apertura-locales-cita-previa",
                text: "Apertura de pequeños locales con cita previa para la atención individual de clientes. Por ejemplo, recogida de comida en restaurantes."
            }
        ]
    },
    {
        phaseNum: "Fase 1",
        name: "Inicial",
        period: "Mínimo 2 semanas*",
        startDate: "Lunes 11 de mayo*",
        activities: [
            {
                img: "reuniones-10-personas",
                text: "Se permiten reuniones sociales de hasta 10 personas respetando la distancia física."
            },
            {
                img: "apertura-comercios-pequennos",
                text: "Apertura del pequeño comercio"
            },
            {
                img: "terrazas-mitad-aforo",
                text: "Apertura de terrazas (ocupación hasta el 50%)."
            },
            {
                img: "apertura-hoteles",
                text: "Apertura de hoteles y de alojamientos turísticos excluyendo zonas comunes."
            },
            {
                img: "lugares-culto-limitados",
                text: "Los lugares de culto tendrán limitación del 30%."
            },
            {
                img: "sector-agro-pesca",
                text: "Sector agroalimentario y pesquero."
            },
            {
                img: "entrenamientos-profesionales",
                text: "Entrenamiento medio en ligas profesionales."
            },
            {
                img: "deporte-no-profesional",
                text: "Deporte no profesional: para actividades que no impliquen contacto físico ni uso de vestuarios."
            },
            {
                img: "mercados-aire-libre",
                text: "Mercados al aire libre, con condiciones de distanciamiento entre los puestos."
            },
            {
                img: "espectaculos-limitados",
                text: "Espectáculos culturales de menos de 30 personas en lugares cerrados (con un tercio de aforo) y de menos de 200 personas al aire libre."
            },
            {
                img: "museos-limitados",
                text: "Visitas a museos limitadas a un tercio del aforo."
            },
            {
                img: "velatorios-limitados",
                text: "Velatorios: para un número limitado de asistentes."
            }
        ]
    },
    {
        phaseNum: "Fase 2",
        name: "Intermedia",
        period: "Mínimo 2 semanas*",
        activities: [
            {
                img: "apertura-restaurantes-limitado",
                text: "Apertura de restaurantes para el servicio de mesas, con limitación de aforo."
            },
            {
                img: "segundas-residencias-provincia",
                text: "Viajes a segundas residencias, solo si están en la misma provincia."
            },
            {
                img: "cines-teatros-tercio-aforo",
                text: "Cines y teatros con un tercio del aforo. Se podrán visitar monumentos y salas de exposiciones."
            },
            {
                img: "actividades-culturales-limitadas",
                text: "Actividades culturales con menos de 50 personas sentadas. Si son al aire libre, menos de 400 personas sentadas."
            },
            {
                img: "caza-pesca",
                text: "Caza y pesca."
            },
            {
                img: "centros-educativos",
                text: "Centros educativos (refuerzo, cuidado de menores de seis años y Selectividad)."
            },
            {
                img: "centros-comerciales",
                text: "Reapertura de centros comerciales, prohibiéndose la permanencia en las áreas comunes o zonas recreativas."
            },
            {
                img: "bodas-limitadas",
                text: "Bodas para un número limitado de asistentes."
            }
        ]
    },
    {
        phaseNum: "Fase 3",
        name: "Avanzada",
        period: "Mínimo 2 semanas*",
        activities: [
            {
                img: "restaurantes-disminuyen-limitaciones",
                text: "En restauración disminuirán las restricciones de aforo, pero con estricta separación entre el público."
            },
            {
                img: "flexibilidad-movilidad",
                text: "Se flexibilizará la movilidad general."
            },
            {
                img: "ampliacion-ocupacion",
                text: "Se ampliará la ocupación de espacios, como el comercio, por ejemplo, hasta un 50% de su aforo."
            },
            {
                img: "discotecas-bares-tercio-aforo",
                text: "Discotecas y bares nocturnos con un aforo máximo de un tercio del habitual."
            },
            {
                img: "playas",
                text: "Apertura de playas en condiciones de seguridad y distanciamiento."
            },
            {
                img: "toros",
                text: "Toros: con una limitación de aforo que garantice una persona por cada 9 metros cuadrados."
            }
        ]
    }

]

export const indicators  = [
    {
        img: "sistema-sanitario",
        text: "Capacidades estratégicas: que incluyen una asistencia sanitaria reforzada, un modelo eficaz y seguro de alerta y vigilancia epidemiológica, una capacidad de detección y control precoz de las fuentes de contagio y un refuerzo de las medidas de protección colectiva"
    },
    {
        img: "situacion-epidemiologica",
        text: "Indicadores económicos: el seguimiento de la actividad económica, la evaluación de la situación por sectores o el impacto de las decisiones tomadas son algunos de los parámetros que afectarán en el avance del plan de desescalada."
    },
    {
        img: "indicadores-sociales",
        text: "Indicadores sociales: el impacto de la enfermedad, el confinamiento y la desescalada en los colectivos sociales más vulnerables, como personas mayores, son otros de los factores que se tendrán en cuenta para la toma de decisiones."
    },
    {
        img: "datos-movilidad",
        text: "Indicadores de movilidad: tanto dentro del país, entre municipios/provincias, como internacional, que permitan realizar el seguimiento de la pandemia, valorar la eficacia del proceso gradual de desconfinamiento y el riesgo de contagio."
    }
]